<template>
    <modal ref="modalDetallePago" titulo="Detalle del pago" no-adicional no-cancelar>
        <div class="row mx-0 py-3" />
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0 a-center mb-3">
                    <img v-if="infoPago.tipo == 1" src="/img/ilustraciones/cash.svg" class="obj-cover" height="36" />
                    <img v-else src="/img/icons/pago_online.svg" class="obj-cover" height="36" />
                    <p class="col text-general f-17 f-600">
                        {{ formatNumero(infoPago.valor) }}
                    </p>
                    <div :class="`${estadoPago(infoPago.estado)} mn-pill`">
                        {{ textoPago(infoPago.estado) }}
                    </div>
                </div>
                <div class="row mx-0 mb-3">
                    <div class="col-5 px-0 f-600 text-general f-15">
                        Pedido
                    </div>
                    <p class="col text-general">
                        {{ infoPedido.id }} <br />
                        {{ formatearFecha(infoPedido.entrega_fecha, formatoDestino='DD/MM/YYYY' ) }}
                    </p>
                </div>
                <div class="row mx-0 mb-3">
                    <div class="col-5 px-0 f-600 text-general f-15">
                        {{ $config.vendedor }}
                    </div>
                    <p class="col text-general">
                        {{ infoPedido.tienda_nombre }}
                    </p>
                </div>
                <div v-if="infoPago.tipo == null" class="row mx-0 mb-3">
                    <div class="col-5 px-0 f-600 text-general f-15">
                        Ref. pago
                    </div>
                    <p v-if="infoPago.pasarela_payu != null" class="col text-general">
                        {{ infoPago.pasarela_payu.transaction_id }}
                    </p>
                </div>
                <div class="row mx-0 mb-3">
                    <div class="col-5 px-0 f-600 text-general f-15">
                        Fecha y hora <br /> del pago
                    </div>
                    <p class="col text-general">
                        {{ formatearFecha( infoPago.created_at, formatoDestino='DD MMM YYYY - hh:mm a') }} 
                    </p>
                </div>
                <div class="row mx-0 mb-3">
                    <div class="col-5 px-0 f-600 text-general f-15">
                        Medio de pago
                    </div>
                    <p v-if="infoPago.tipo == null" class="col text-general">
                        En linea
                    </p>
                    <p v-else-if="infoPago.tipo == 1" class="col text-general">
                        Efectivo
                    </p>
                    <p v-else-if="infoPago.tipo == 2" class="col text-general">
                        Transferencia
                    </p>
                </div>
                <div class="row mx-0 mb-3">
                    <div class="col-5 px-0 f-600 text-general f-15">
                        Observación
                    </div>
                    <p class="col text-general">
                        {{ infoPago.comentario }}
                    </p>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
export default {

    data(){
        return {
            infoPago: {},
            infoPedido: {}
        }
    },
    methods: {
        toggle(item, pedido){
            this.infoPago = item;
            this.infoPedido = pedido
            this.$refs.modalDetallePago.toggle();
        },
        estadoPago(state){
            switch (state){
            case 0:
            case 2:
                return 'bg-aprobado';
            case 9:
            case 1:
                return 'bg-pendiente';
            case 3:
                return 'bg-rechazado';
            }
        },
        textoPago(state){
            switch (state){
            case 0:
            case 2:
                return 'Aprobado';
            case 9:
            case 1:
                return 'Pendiente';
            case 3:
                return 'Rechazado';
            }
        },
    },
}
</script>

<style lang="scss" scoped>

.bg-rechazado{
    background-color: #FF3B63;
    height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 400 !important;
    font-size: 14px;
}
.bg-aprobado{
    background-color: #28D07B;
    height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 400 !important;
    font-size: 14px;
}
.bg-pendiente{
    background-color: #F5F5F5;
    height: 24px;
    display: flex;
    align-items: center;
    color: var(--color-general2);
    font-size: 400 !important;
    font-size: 14px;
}
</style>